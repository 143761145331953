<template>
  <div class="min-h-screen flex bg-paleBlue">
    <div
      class="flex w-[722px] bg-[linear-gradient(90deg,_#322ED6_0%,_#171759_100%)] justify-center rounded-br-[60px] rounded-tr-[70px] rounded-bl-[0] pt-[120px] [box-shadow:0px_0px_4px_0px_#000000]">
      <div class="px-3">
        <img src="~/assets/images/servers.png" alt="" class="w-full max-w-full h-auto" />
      </div>
    </div>
    <main class="w-[calc(100% - 722px)] mx-auto pt-[80px] ">
      <div class="block w-[642px] md:max-xl:px-6">
        <slot />
      </div>
    </main>
  </div>
</template>
<script setup lang="ts">
</script>